import React, { useEffect, useState } from "react";
import Blogbox from './Blogbox';
import Connect from './Connect';
import Home_testimonial from './Home-testimonial';
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import axios from "axios";
import Config from "./Config/Config";
import { SyncLoader } from "react-spinners";
import { Col, Row } from "react-bootstrap";


const Blogs = () => {

    const [blogs , setBlogs] = useState(null);
    const [loading , setLoading] = useState(false);


    const fetchBlogsList = async () => {

        try {
            
            let payloads = {
                "page_no":'1',
                "per_page_record":'10',
                "scope_fields":['content' , 'meta_h_one_tag' , 'page_slug']
            }

            setLoading(true)

            let response = await axios.post(`${Config.API_URL}/api/customer/blog/list` , payloads)

            if(response.status === 200){

                setBlogs(response.data.data)

            }else {
                setBlogs(null);
            }

            setLoading(false)


        } catch (error) {
            
            setLoading(false)

            console.log(error?.message);
            
        }

    }

    useEffect(() => {

        fetchBlogsList()

        // make scroll on top

        window.scrollTo({ top: 0, behavior: 'smooth' });


    } , [])


    





    return (
        <>

            <div className="container">
                <div className="row pt-4">
                    <h3 className="sitehdngs">Our Blogs</h3>
                </div>
                <div className="row pb-4">
                    {/* <Blogbox /> */}

                    {
                        loading ? 

                        <Row>
                            <Col xs={12} md={12} lg={12} className="text-center">
                                <SyncLoader />
                            </Col>
                        </Row>
                       
                        :  blogs && blogs?.length > 0 ? 
                        blogs.map((blog , index) => {
                            
                            return <Blogbox blog={blog}/>
                        }) 
                        : null

                    }
                </div>
            </div>

            <Connect />
            <Home_testimonial />
            <Pop_routes />
            <Footer />
        </>
    )
}


export default Blogs;

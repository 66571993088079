import React, { useEffect, useState } from "react";
//import PhoneIcon from '@mui/icons-material/Phone';

import SearchSoftware from './Software';
import Home_about from './Home-about';
import Why_buzz from './Why-buzzway';
import Connect from './Connect';
import Download_apps from './Download-apps';
import Home_testimonial from './Home-testimonial';
import Pop_routes from './Popular-routes';
import Footer from './Footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import AlertPopupMessage from "./ErrorPopus/AlertPopus";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Config from "./Config/Config";
import { useLocation } from "react-router-dom";
import { useConfig } from "./Context/ConfigContext";

const Home = () => {
    const [HomePageCms, setHomePageCms] = useState([])
      const { config, loading, error } = useConfig();
    
    const currentLocation = useLocation();
    
    const [metaTags, setMetaTags] = useState({
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        meta_image: '',
    })

    const HomePageCmsApiCall = async () => {
        try {
            let Payloads = {
                page_type: 'home-page'
            }
            let response = await axios.post(`${Config.API_URL}/api/customer/common/cms/list`, Payloads);
            if (response.data.status) {
                setHomePageCms(response.data.data[0])
            } else {
                setHomePageCms([]);
            }
        } catch (error) {
            console.log(error.message);
            setHomePageCms([]);
        }
    }

    useEffect(() => {
        HomePageCmsApiCall();
    }, [])

    const travelAgencyJsonLd = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Buzzway",
        "image": config?.website_logo ? `${Config.IMG}` + config.website_logo : `${Config.BASE_URL}/logo.png`,
        "@id": metaTags.meta_image ? `${Config.IMG}` + metaTags.meta_image : `${Config.BASE_URL}/logo.png`,
        "url": "https://www.buzzway.in/",
        "telephone": "+919054865866",
        "priceRange": "0",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": ", Shital House, near Denny Coffee Bar, opp. New York Tower A, Thaltej",
            "addressLocality": "Ahmedabad",
            "postalCode": "360001",
            "addressCountry": "IN"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 23.0536039,
            "longitude": 72.5195122
        },
        "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
        },
        "sameAs": [
            "https://www.buzzway.in/",
            "https://in.pinterest.com/Buzzwaycar/",
            "https://www.linkedin.com/in/buzzway/",
            "https://youtube.com/channel/UCmk1a_siIybosrgdJrHCcww",
            "https://www.instagram.com/buzzwaytaxiservice/",
            "https://www.facebook.com/buzzway01/",
            "https://twitter.com/Buzzway01",
            "https://www.tumblr.com/buzzwaytaxi"
        ]
    };

    useEffect(() => {
        setMetaTags({
            meta_title: HomePageCms.meta_title,
            meta_description: HomePageCms.meta_description,
            meta_keywords: HomePageCms.meta_keyword,
            meta_image: HomePageCms.banner_image_jpeg,
        })
    }, [HomePageCms.banner_image_jpeg, HomePageCms.meta_description, HomePageCms.meta_keyword, HomePageCms.meta_title])

    return (
        <>
            <AlertPopupMessage />
            <Helmet>
                <title>{config?.site_title}</title>
                <meta name="title" content={config?.site_title} />
                <meta name="description" content={config?.meta_description} />
                <meta name="keywords" content={config?.meta_keywords} />
                {/* Facebook OG */}
                <meta property="og:title" content={config?.site_title} />
                <meta property="og:url" content={`${Config.BASE_URL}` + currentLocation.pathname} />
                <meta property="og:description" content={config?.meta_description} />
                <meta property="og:keywords" content={config?.meta_keywords} />
                <meta property="og:image" content={`${Config.IMG}` + config?.website_logo} />
                {/* Twitter OG */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={config?.site_title} />
                <meta name="twitter:description" content={config?.meta_description} />
                <meta name="twitter:keywords" content={config?.meta_keywords} />
                <meta name="twitter:url" content={`${Config.BASE_URL}` + currentLocation.pathname} />
                <meta name="twitter:image" content={`${Config.IMG}` + config?.website_logo} />
                <link rel="canonical" href={`${Config.BASE_URL}` + currentLocation.pathname} />

                <script type="application/ld+json">
                    {JSON.stringify(travelAgencyJsonLd)}
                </script>
            </Helmet>
            <AnimationOnScroll animateIn="animate__fadeInDown">

                <section className="homebanner">
                    <div className="container">
                        <div className="bnrtext">
                            <h1>Ride with ease and arrive in style with our trusted taxi service.</h1>
                        </div>
                        <SearchSoftware />

                    </div>
                </section>
            </AnimationOnScroll>
            <Home_about />

            <Why_buzz />

            <Connect />

            <Download_apps />

            <Home_testimonial />

            <Pop_routes />

            <Footer />
        </>
    );
};

export default Home;

import React, { useState, useEffect } from "react";
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import axios from "axios";
import Config from "./Config/Config";
import { Link } from "react-router-dom";
const Pop_routes = () => {
    const [cms_data, set_cms_data] = useState([]);
    const [city_routes, set_city_routes] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [showAllPopular, setShowAllPopular] = useState(false);
    const [TotalPopupRecords, setTotalPopularRecords] = useState(0);
    const [recordsToShow, setTotalRecords] = useState(0);

    const [pageSlugs, setPageSlugs] = useState([]);


    // city cms api call
    const common_city_api = async () => {
        try {
            const response = await axios.get(`${Config.API_URL}/api/customer/cms/city_records`);
            if (response.data.status) {
                set_cms_data(response.data.data);
            } else {
                set_cms_data([]);
            }
        } catch (error) {
            console.log(error)
            set_cms_data([]);
        }
    }

    const handle_city_routes_api = async () => {
        try {
            let response = await axios.get(`${Config.API_URL}/api/customer/cms/city_routes_page`);
            if (response.data.status) {
                set_city_routes(response.data.data)
            } else {
                set_city_routes([]);
            }
        } catch (error) {
            console.log(error.message)
            set_city_routes([]);
        }
    }

    const getParentRecords = async (parentId, tabId) => {
        try {

            let payloads = {
                parent_id: parentId,
                tab_id: tabId,
            }

            let response = await axios.post(`${Config.API_URL}/api/customer/cms/parent-records`, payloads);
            if (response.data.status) {
                return response?.data?.data?.[0]?.page_slug
            } else {
                return ''
            }
        } catch (error) {
            console.log(error.message)
            return ''
        }
    }

    // convert to slug name
    function convertToSlug(text) {
        return text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }



    useEffect(() => {
        common_city_api();
        handle_city_routes_api();
    }, [])

    useEffect(() => {
        setTotalPopularRecords(showAllPopular ? city_routes.length : 9);
        setTotalRecords(showAll ? cms_data.length : 12)
    }, [city_routes.length, cms_data.length, showAll, showAllPopular]);

    useEffect(() => {
        const fetchPageSlugs = async () => {
            const slugs = await Promise.all(
                cms_data?.map(async (data) => {
                    const sortedRecords = data.tab_records?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                    const secondTaId = sortedRecords?.[0]?.ta_id;
                    const slug = await getParentRecords(data._id, secondTaId);
                    return { id: data._id, slug };
                })
            );

            setPageSlugs(slugs);
        };

        fetchPageSlugs();
    }, [cms_data]);



    return (
        <>
            <section className="bgdarkblue poprt_sect">
                <div className="container pt-4 py-4">
                    <div className="row text-center">
                        <h3 >Popular Cites</h3>
                    </div>
                    <div className="row">
                        {/* <ul className="pop_routs">
                            {
                                cms_data.length > 0 && cms_data.slice(0, recordsToShow).map((data, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={`/${convertToSlug(data.tab_data_records[0].tab_name + ' ' + data.from_city_name)}`}>
                                                {data.from_city_name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul> */}

                        {
                            cms_data.length > 0 && cms_data.slice(0, recordsToShow).map((data, index) => {
                                let slug = pageSlugs.find((page) => page.id === data._id)?.slug;
                                return (
                                    <div className="col-sm-2 mt-2" key={index}>
                                        <Link style={{ color: 'white' }} to={`/${slug}`}>
                                            {data.from_city_name}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                        {
                            !showAll && cms_data.length > 4 && (
                                <div className="downarrow mt-2">
                                    {/* Adding here Bounce Class in this tutorials */}
                                    <AnimationOnScroll animateIn='animate__bounce' initiallyVisible={false}>
                                        <button onClick={() => setShowAll(true)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                            <KeyboardDoubleArrowDownSharpIcon />
                                        </button>
                                    </AnimationOnScroll>
                                </div>
                            )
                        }

                        {
                            showAll && cms_data.length > 6 && (
                                <div className="downarrow mt-2">
                                    <AnimationOnScroll animateIn="animate__bounce" initiallyVisible={false}>
                                        <button onClick={() => setShowAll(false)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                            <KeyboardDoubleArrowUpIcon />
                                        </button>
                                    </AnimationOnScroll>
                                </div>
                            )
                        }

                    </div>

                    <div className="row text-center">
                        <h3>Popular Routes</h3>
                    </div>
                    <div className="row">
                        {/* <ul className="pop_routs col-sm-12">
                            {
                                city_routes.length > 0 &&
                                city_routes.slice(0, TotalPopupRecords).map((data, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={`/${data.page_slug}`}>{data.page_name}</Link>
                                        </li>
                                    )
                                })
                            }
                        </ul> */}

                        {
                            city_routes.length > 0 &&
                            city_routes.slice(0, TotalPopupRecords).map((data, index) => {
                                return (
                                    <div key={index} className="col-sm-4 pop_routs mt-2">
                                        <Link to={`/${data.page_slug}`} className="" style={{ color: 'white' }}>{data.page_name}</Link>
                                    </div>
                                )
                            })
                        }



                        {
                            !showAllPopular && city_routes.length > 5 && (
                                <div className="downarrow mt-2">
                                    {/* Adding here Bounce Class in this tutorials */}
                                    <AnimationOnScroll animateIn='animate__bounce' initiallyVisible={false}>
                                        <button onClick={() => setShowAllPopular(true)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                            <KeyboardDoubleArrowDownSharpIcon />
                                        </button>
                                    </AnimationOnScroll>
                                </div>
                            )
                        }
                        {
                            showAllPopular && city_routes.length > 9 && (
                                <div className="downarrow mt-2">
                                    <AnimationOnScroll animateIn="animate__bounce" initiallyVisible={false}>
                                        <button onClick={() => setShowAllPopular(false)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                            <KeyboardDoubleArrowUpIcon />
                                        </button>
                                    </AnimationOnScroll>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pop_routes; 
import React, { useEffect } from "react";
import adbnr from "../src/images/adbnr.png";
import sideimg from "../src/images/sideimg.png";
import sideapp from "../src/images/sideapp.png";
import { Helmet } from "react-helmet-async";
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import Side_quick from './Side_quick';
import SearchSoftware from './Software';
import Faqs from './Faq';
import Cms_tablike from './Cms-tablike';
import { Link, useLocation } from "react-router-dom";
import Cab_box from './Cab-box';
import Fare_table from './Fare-table';
import Service_routes from './Service-routes';
import Major_routes from './Major-routes';
import Popular_tourist from './Popular-tourist';
import Cms_review from './Cms-review';
import Call_booknow from './Call-booknow';
import _ from 'lodash';
import { useState } from "react";
import Config from "./Config/Config";
import axios from "axios";
import AlertPopupMessage from "./ErrorPopus/AlertPopus";
import { Col, Row } from "react-bootstrap";
import { SyncLoader } from "react-spinners";


const Cms = () => {
    const [Tab_data, set_Tab_data] = useState([]);
    const [Tabs_records, set_tab_records] = useState({});
    const [city_routes, set_city_routes] = useState({});
    const [checkCityPage, setCheckCityPage] = useState([]);
    const [popularRoutes, setPopularRoutes] = useState({});
    const [lessData, showMoreData] = useState(true);
    const Location = useLocation();
    const [subPages, setSubPages] = useState(null);
    const [subPageData, setSubPageData] = useState({});
    const [allowShowSubPageCom, setAllowShowPageComponents] = useState(false)

    const [loaders, setLoaders] = useState(false);

    // verified the city page records data
    const verifiedPageRecordsData = async (page_slug) => {
        setLoaders(true);
        try {
            let response = await axios.post(`${Config.API_URL}/api/customer/cms/city_records`, { "page_slug": page_slug })
            if (response.data.status) {
                setCheckCityPage(response.data.data)
            } else {
                setCheckCityPage({});
            }
        } catch (error) {
            console.log(error.message)
            setCheckCityPage({});
        }
    }

    // find the Sub Records By Id ->>>>> 

    const findSubRecordsById = async (id) => {

        setAllowShowPageComponents(true)

        try {
            let response = await axios.post(`${Config.API_URL}/api/customer/cms/sub-record-by-id`, { "id": id })
            if (response.data.status) {
                setSubPageData(response.data.data)
                setLoaders(false)
            } else {
                setSubPageData({});
                setLoaders(false)
            }
        } catch (error) {
            console.log(error.message)
            setSubPageData({});
            setLoaders(false)
        }
    }

    // handle the Tabs records data to selected tabs
    const Tab_records_data = async (city_name, parentId) => {
        try {
            let response = await axios.post(`${Config.API_URL}/api/customer/cms/city_records`, { "from_city_name": city_name, parent_id: parentId })
            if (response.data.status) {
                set_Tab_data(response.data.data)
            } else {
                set_Tab_data([]);
            }
        } catch (error) {
            console.log(error.message)
            set_Tab_data([]);
        }
    }

    // handle the cms city cms data
    useEffect(() => {
        if (Object.keys(checkCityPage).length > 0 && checkCityPage.page_type === 'city_page') {
            if (checkCityPage?.sub_parent_id === null || checkCityPage?.sub_parent_id === "undefined") {
                Tab_records_data(checkCityPage.from_city_name, checkCityPage?.parent_id)
                setPopularRoutes([])
            } else {
                set_city_routes(checkCityPage)
                setPopularRoutes([])
                set_Tab_data([]);
                findSubRecordsById(checkCityPage?.sub_parent_id)
            }
        } else if (Object.keys(checkCityPage).length > 0 && checkCityPage.page_type === 'city_routes') {
            set_city_routes(checkCityPage)
            setAllowShowPageComponents(false)
            setPopularRoutes([])
            set_Tab_data([]);
        } else {
            set_city_routes(checkCityPage)
            findSubRecordsById(checkCityPage?.sub_parent_id)
            setPopularRoutes([])
            set_Tab_data([]);
        }
    }, [checkCityPage, Location.pathname])

    useEffect(() => {
        let page_slug = Location.pathname.slice(1);
        verifiedPageRecordsData(page_slug);
    }, [Location.pathname]);

    // useEffect(() => {
    //     if (Object.entries(popularRoutes).length > 0) {
    //         set_tab_records(popularRoutes)
    //         set_Tab_data([]);
    //     }
    // }, [popularRoutes])

    useEffect(() => {

        if (subPages) {
            findSubRecordsById(subPages?.id)
        }

    }, [subPages])

    const productSchema = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": Tabs_records?.meta_title || city_routes?.meta_title,
        "image": Tabs_records?.banner_image_jpeg ? Config.IMG + Tabs_records?.banner_image_jpeg : Config.IMG + Tabs_records.city_image_jpeg,
        "description": Tabs_records.meta_description || city_routes.meta_description,
        "brand": {
            "@type": "Brand",
            "name": "Buzzway"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "bestRating": "5",
            "worstRating": "3",
            "ratingCount": "2497"
        }
    }

    const hasMore = () => {
        if (lessData) {
            showMoreData(false);
        } else {
            showMoreData(true);
        }
    }


    useEffect(() => {
        return () => {
            set_tab_records([])
            set_Tab_data([])
            showMoreData(false)
            setCheckCityPage([])
            setSubPages([])
            setPopularRoutes([])
            setSubPageData([])
            set_city_routes([])
        }
    }, [Location.pathname])



    return (
        <>
            <AlertPopupMessage />
            <Helmet>
                <title>{Tabs_records?.meta_title || city_routes?.meta_title}</title>
                <meta name="description" content={Tabs_records.meta_description || city_routes.meta_description} />
                <meta name="keywords" content={Tabs_records.meta_keyword || city_routes.meta_keyword} />

                <meta property="og:title" content={Tabs_records?.meta_title || city_routes?.meta_title} />
                <meta property="og:url" content={`${Config.BASE_URL}` + Location.pathname} />
                <meta property="og:type" content="article" />
                <meta property="og:description" content={Tabs_records.meta_description || city_routes.meta_description} />
                <meta property="og:keywords" content={Tabs_records.meta_keyword || city_routes.meta_keyword} />
                <meta property="og:image" content={Tabs_records?.banner_image_jpeg ? Config.IMG + Tabs_records?.banner_image_jpeg : Config.IMG + Tabs_records.city_image_jpeg} />
                {/* Twitter OG */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={Tabs_records?.meta_title || city_routes?.meta_title} />
                <meta name="twitter:description" content={Tabs_records.meta_description || city_routes.meta_description} />
                <meta name="twitter:keywords" content={Tabs_records.meta_keyword || city_routes.meta_keyword} />
                <meta name="twitter:url" content={`${Config.BASE_URL}` + Location.pathname} />
                <meta name="twitter:image" content={Tabs_records?.banner_image_jpeg ? Config.IMG + Tabs_records?.banner_image_jpeg : Config.IMG + Tabs_records.city_image_jpeg} />
                <link rel="canonical" href={`${Config.BASE_URL}` + Location.pathname} />

                <script type="application/ld+json">
                    {JSON.stringify(productSchema)}
                </script>

            </Helmet>
            {
                    <>
                        <div className="full_softwr bgdarkblue">
                            <div className="container py-4">
                                {/* <h1>{Tabs_records?.page_name ? Tabs_records?.page_name : Tabs_records?.page_name || ''}</h1> */}
                                <SearchSoftware />
                            </div>
                        </div>
                        <div className="container">
                            <div className="row py-4 cmspage">
                                <div className="col-sm-9">
                                    <div className="cms-content">
                                        <h1 style={{fontSize:'2rem'}}>{Tabs_records.h_one_tag}</h1>
                                        {
                                            (Tabs_records?.banner_image_jpeg || Tabs_records?.city_image_jpeg) &&
                                            <img src={`${Config.IMG}${Tabs_records?.banner_image_jpeg ? Tabs_records?.banner_image_jpeg : Tabs_records.city_image_jpeg}`} alt="imageTag" />
                                        }
                                        {
                                            Object.entries(popularRoutes).length > 0 ? null :
                                                <Cms_tablike common_cms={Tab_data} tab_records_data={set_tab_records} Tabs_data={set_Tab_data} context_data={Tabs_records} routesPages={city_routes} setSubPages={setSubPages} setLoaders={setLoaders}/>
                                        }
                                    </div>
                                    <div className="cms-cabs">
                                        <div className="row">
                                        </div>
                                    </div>

                                    {/* Sab Pages Records */}

                                    {
                                        allowShowSubPageCom &&


                                        <div className="cardlike mt-4">
                                            <Row className="text-start mt-2 ms-3">
                                                <h5> Related Cab Service Pages </h5>
                                            </Row>

                                            <Row className="text-start">
                                                {Array.isArray(subPageData) && subPageData.length > 0 ? (
                                                    (lessData ? subPageData.slice(0, 12) : subPageData).map((subPage, index) => (
                                                        <Col sm={4} key={index} className="servroutes">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <Link to={`/${subPage?.page_slug || '#'}`}>
                                                                        {subPage?.page_name}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                    ))
                                                ) : <span className="text-center">No Routes Found</span>}

                                                {subPageData.length > 12 && (
                                                    <div className="text-start mt-3">
                                                        <button className="rdmore_btn" onClick={hasMore}> {lessData ? 'Read More' : 'Read Less'} </button>
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                    }



                                    <Fare_table fair_data={Tabs_records} />

                                    <Service_routes tab_records_data={set_tab_records} Tabs_data={set_Tab_data} />
                                    <Major_routes Major_routes={Tabs_records} />
                                    <Faqs faq_data={Tabs_records} />
                                    <Popular_tourist tab_records_data={set_tab_records} Tabs_data={set_Tab_data} PopularCity={setPopularRoutes} popularData={popularRoutes} />
                                    <Cms_review />
                                    <Call_booknow />
                                    <div className="midbnr">
                                        <a href="https://play.google.com/store/apps/details?id=com.buzzwaycustomer&hl=en"> <img src={adbnr} alt="imageTag" /></a>
                                    </div>
                                </div>
                                <div className="col-sm-3 cmssidebr">
                                    <a href="https://play.google.com/store/apps/details?id=com.buzzwaycustomer&hl=en"> <img src={sideapp} alt="sidebar" /></a>
                                    <img src={sideimg} alt="sidetitle"/>
                                    <Side_quick />
                                </div>
                            </div>
                        </div>
                    </>
            }


            <Pop_routes />

            <Footer />
        </>
    )
}


export default Cms;
